import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import CommonButton from "../component/button/CommonButton";
import logo from "../images/logo.png";
import Alert from "../images/alert.png";
import { forget, resendOtp, verifyLogin } from "../api/auth";
import "./login.css";
import CircularProgress from "@mui/material/CircularProgress";
import { PushNotifications } from "@capacitor/push-notifications";
import { FCM } from "@capacitor-community/fcm";
import { requestForToken } from "../utils/firebase";
import { isPlatform } from "@ionic/react";
import { useNavigate, useParams } from "react-router-dom";
const VerifyOtp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isdisable, setIsdisable] = useState(false);
  const [otp, setOtp] = useState("");
  const [deviceId, setDeviceId] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const Params = useParams;
  let { email } = Params();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const verifyEmail = {
      email,
      deviceId: deviceId ? deviceId : undefined,
      otp,
    };
    console.log("<VerifyOtp> verifyEmail", verifyEmail);
    const response = await verifyLogin(verifyEmail);

    if (response.remote === "success") {
      setIsLoading(false);
      setSuccessMessage("Verification code verified successfully.");
      navigate("/");
    } else {
      setIsLoading(false);
      setErrorMsg("Unable to verify code.");
    }
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();
    setIsdisable(true);
    const verifyEmail = {
      email,
    };
    const response = await resendOtp(verifyEmail);

    if (response.remote === "success") {
      setIsdisable(false);
      setSuccessMessage("Verification code send successfully.");
    } else {
      setIsdisable(false);
      setErrorMsg("Unable to resend code.");
    }
  };
  useEffect(() => {
    if (!deviceId) {
      console.log(
        isPlatform("android"),
        isPlatform("ios"),
        isPlatform("capacitor")
      );
      if (
        isPlatform("android") ||
        isPlatform("ios") ||
        isPlatform("capacitor")
      ) {
        PushNotifications.requestPermissions()
          .then((_) =>
            PushNotifications.register()
              .then(() => {
                FCM.getToken()
                  .then((result) => setDeviceId(result.token))
                  .catch((err) => {});
              })
              .catch(console.log)
          )
          .catch(console.log);
      } else {
        requestForToken()
          .then((token) => {
            console.log("<VerifyOtp> token", token);
            token && setDeviceId(token);
          })
          .catch((e) => console.log(e));
      }
    }
  }, [otp]);

  return (
    <>
      <div className="login">
        <div className="login-form col-lg-5 mx-auto">
          <span
            className="text-white font-weight-bold text-capitalize  bg-success"
            style={{ fontSize: "13px" }}
          >
            {successMessage}
          </span>
          <div className="login-form-header">
            <img src={logo} alt="" />
            <h3>Verify OTP ?</h3>
            <p className="forgot-text">
              Please enter the verification code which has been sent to your
              email address.
            </p>
          </div>
          <div className="login-form-body">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Verification code </Form.Label>
                <Form.Control
                  type=""
                  placeholder="Enter your verification code."
                  onChange={(e) => {
                    setErrorMsg();
                    setOtp(e.target.value);
                  }}
                  value={otp}
                  required
                />
              </Form.Group>
              <span className="text-red ms-1"> {errorMsg}</span>
              <Form.Group className="mb-3">
                {/* <Link to="/reset-password"> */}
                <CommonButton
                  children="Submit"
                  className="btn-common w-100"
                  submit="submit"
                >
                  {isLoading && (
                    <Stack
                      sx={{ justifyContent: "center" }}
                      spacing={2}
                      direction="row"
                    >
                      <CircularProgress />
                    </Stack>
                  )}
                  Submit
                </CommonButton>
                {/* </Link> */}
              </Form.Group>

              <Form.Group className="mb-3">
                {/* <Link to="/reset-password"> */}
                <CommonButton
                  children="Forgot Password ?"
                  className="btn-common w-100"
                  onClick={(e) => {
                    handleResendOtp(e);
                  }}
                  disabled={isdisable ? true : false}
                >
                  {/* {isLoading && (
                    <Stack
                      sx={{ justifyContent: "center" }}
                      spacing={2}
                      direction="row"
                    >
                      <CircularProgress />
                    </Stack>
                  )} */}
                  Resend OTP
                </CommonButton>
                {/* </Link> */}
              </Form.Group>

              <Form.Group className="mb-3">
                <Stack spacing={0} direction="row" alignItems="center">
                  <img src={Alert} alt="" />
                  <span className="text-red ms-1">
                    You are not authorized to sing in, please contact Support.
                  </span>
                </Stack>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};
export default VerifyOtp;
