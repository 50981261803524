import { Box, Card, CardContent, Grid, Switch } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import TableList from "../../table";
import { Link, useSearchParams } from "react-router-dom";
import {
  ActionIcon,
  formateUserData,
  // USER_COLUMNS_DATA,
  ActionIconDelete,
  UserIcon,
  UserTest,
  ActionIconPauseUser,
} from "../../table/Data";
import { Stack } from "@mui/material";
import EditModal from "../../modal/EditModal";
import plushicon from "../../../../images/plushicon.png";
import {
  Alluser,
  DeleteUser,
  FreezeUser,
  GetUserAds,
} from "../../../../api/auth";
import ReactPaginate from "react-paginate";
import { usePermission } from "../../../Permission";
import { USER_COLUMNS_DATA } from "../../table/Data_guest";
import { toast } from "react-toastify";
import moment from "moment";
import ReactCountryFlag from "react-country-flag";
import { getCode } from "country-list";

const LedProAdminUserManagement = () => {
  const [open, setOpen] = React.useState(false);
  const [userData, setUserData] = React.useState([]);
  const [dataId, setDataId] = React.useState(null);
  const [sortings, setSortings] = React.useState([]);
  const [query] = useSearchParams();
  const [pendingAds, setpendingAds] = useState([]);
  const [activeAds, setactiveAds] = useState([]);
  const [freezeUser, setFreezeUser] = useState(false);

  const hasCreatePermission = usePermission("user-create");
  const hasEditPermission = usePermission("user-edit");
  const hasCreateUpdateDeletePermission = usePermission("user-edit");

  const handleClickOpen = useCallback(
    (id) => {
      setOpen(!open);
      if (dataId) {
        setDataId(null);
      } else {
        setDataId(id);
      }
    },
    [dataId, open]
  );

  // Pagination Start
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);

  const DeleteData = useCallback(async (id) => {
    if (window.confirm("Do You Want To delete User ?")) {
      let deleteusers = await DeleteUser(id);

      if (deleteusers.remote === "success") {
        setUserData((prevState) => prevState.filter((user) => user.id !== id));
      }
    }
  }, []);

  const FreezeUserData = useCallback(async (id) => {
    let response = await FreezeUser(id, {});

    if (response.remote === "success") {
      setFreezeUser(!response?.data?.data?.active);
      // console.log(
      //   "<GuestUserManagement> FreezeUserData",
      //   response?.data?.data?.active
      // );
      response?.data?.data?.active
        ? toast.success("User Unfreezed")
        : toast.warn("User Freezed");
    }
  }, []);

  const getAlluser = useCallback(
    async (page) => {
      const limit = 20;
      const promise = Alluser(limit, page, sortings || "",null, "LedproAdmin,Admin");

      const response = await promise;

      if (response.remote === "success") {
        setTotalPages(
          Math.ceil(response?.data?.data?.total / limit || 1)
        );

        response.data.data.docs = response.data.data.docs.filter(
          (mydata) =>
            mydata.role === "LedproAdmin" || mydata.ledproAdmin === true
        );

        const myalldata = response.data.data.docs.map((mydata) => {
          if (mydata?.role === "LedproAdmin" || mydata.ledproAdmin === true) {
            return {
              id: mydata?._id,
              name: mydata.firstname
                ? mydata.firstname + " " + mydata.lastname
                : "Not Available",
              email: mydata.email,
              role: mydata.role,
              permissions: mydata.permission,
              lastlogin: mydata.lastLogin,
              online: mydata.online,
              photo: mydata.photo,
              phone: mydata.phone,
              registerAt: moment(mydata.createdAt).format("DD-MM-YYYY"),
              active: mydata.active,
              country: mydata.address.country,
            };
          }
        });

        // console.log("<GuestUserManagement> mydata", myalldata);

        const newUserData = myalldata.map((item, index) => {
          const countryCode = getCode(item.country);
          return {
            id: item.id.toString(),
            firstname: (
              <UserIcon
                editPermission={hasEditPermission}
                name={item.name}
                id={item.id}
                photo={item.photo}
                userType={"GuestUser"}
              />
            ),
            email: item.email,
            // online: (
            //   <UserTest
            //     className={item.online ? "text-green" : "text-red"}
            //     text={item.online ? "Online" : "Offline"}
            //   />
            // ),
            phone: item.phone,
            role: item.role,
            permissions: item.permissions,
            registerAt: item.registerAt,
            lastLogin: item.lastlogin
              ? item.lastlogin.slice(0, 10)
              : "Not Logged In",
            country: (
              <ReactCountryFlag
                countryCode={countryCode}
                svg
                style={{
                  width: "2em",
                  height: "2em",
                }}
                title={item.country}
              />
            ), // Add the flag
            action: (
              <Stack
                style={{ position: "sticky", right: 0 }}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                {hasEditPermission && (
                  <>
                    <Link
                      to={`/my-profile/user-edit/${item.id}?user=LedproAdmin`}
                      key={index}
                      state={{ action: "edit" }}
                    >
                      <ActionIcon />
                    </Link>
                    <Box>
                      {pendingAds.length > 0 && activeAds.length > 0 ? (
                        <ActionIconDelete
                          deletepass={() => DeleteData(item.id)}
                        />
                      ) : (
                        <Switch
                          checked={item?.active} // Step 3: Set initial state of toggle
                          onChange={() => {
                            const confirmation = item?.active
                              ? window.confirm("Do You Want To freeze User ?")
                              : window.confirm(
                                  "Do You Want To Unfreeze User ?"
                                );
                            if (confirmation) {
                              setFreezeUser(item?.active); // Step 4: Handle toggle state change
                              FreezeUserData(item?.id); // Call FreezeUserData on toggle
                            }
                          }}
                        />
                      )}
                    </Box>
                  </>
                )}
              </Stack>
            ),
          };
        });

        setUserData([...newUserData]);
      } else {
      }
    },
    [DeleteData, sortings, freezeUser]
  );

  useEffect(() => {
    if (page) {
      getAlluser(page);
      setSortings(query.get("sort"));
    }
  }, [getAlluser, page, query]);

  // Delete User

  const handleSetPage = (data) => {
    let nextPage = data.selected + 1;
    setPage(nextPage);
  };

  return (
    <>
      <Grid item lg={10} xs={12}>
        <Card
          variant="outlined"
          sx={{ minHeight: "calc((100vw/2 - 307px) * 1.34)" }}
          id="table-body-user-list"
        >
          <CardContent>
            <TableList
              rows={userData}
              columns={USER_COLUMNS_DATA}
              handleClickOpen={handleClickOpen}
              deletepass={DeleteData}
              onSort={setSortings}
            />

            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className="mt-3 ms-3"
            >
              {hasCreatePermission && (
                <Link
                  to="/my-profile/user-edit?user=LedproAdmin"
                  style={{ textDecoration: "none", color: "#000" }}
                  state={{ action: "new" }}
                >
                  <span className="addiconbox">
                    <img src={plushicon} alt="" />
                  </span>

                  <span className="addtext ms-2">Add a new user</span>
                </Link>
              )}
              {/* {hasCreateUpdateDeletePermission && (
                <Link
                  to="/my-profile/user-edit?user=LedproAdmin"
                  style={{ textDecoration: "none", color: "#000" }}
                  state={{ action: "new" }}
                >
                  <span className="addiconbox">
                    <img src={plushicon} alt="" />
                  </span>

                  <span className="addtext ms-2">Add a new user</span>
                </Link>
              )} */}
              <ReactPaginate
                previousLabel="<<"
                nextLabel=">>"
                pageRangeDisplayed={10}
                pageCount={totalPages}
                forcePage={page - 1}
                onPageChange={handleSetPage}
                activeClassName={"active"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
              />
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <EditModal open={open} handleClose={() => handleClickOpen(null)} />
    </>
  );
};
export default LedProAdminUserManagement;
