import { lazy } from "react";
import CreateEditAddInvoice from "./invoice/awaiting-approval/create-edit-add.component";
import {
  INVOICE_STATUS,
  INVOICE_TYPE,
} from "./invoice/awaiting-approval/invoice.data";
import InvoiceGenrate from "../pdf/invoice/InvoiceGenrate";
import MainLayout from "../layout/main";
import GuestUserManagement from "./user-management/guest-users";
import LedProAdminUserManagement from "./user-management/ledproAdmin-users";
const UserManagement = lazy(() => import("./user-management"));
const Bilboard = lazy(() => import("./bilboard"));
const UserEdit = lazy(() => import("./user-edit"));
const ExportReport = lazy(() => import("./export-report"));
const UserReportExport = lazy(() =>
  import("./export-report/user-report/user-report")
);
const BillboardReportExport = lazy(() =>
  import("./export-report/billboard-report/billboard-report")
);
const StatisticsReportsExport = lazy(() =>
  import("./export-report/stat-report/stat-report")
);
const UserPermissionManagement = lazy(() =>
  import("./user-management/UserPermissionManagement")
);
const AwaitingApproval = lazy(() => import("./invoice/awaiting-approval"));
const GeneralSetting = lazy(() => import("./general-setting"));
const SMS_CREDIT = lazy(() => import("./sms-credit"));
const ServicesAndItems = lazy(() => import("./services"));

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "my-profile/user-management/users",
    element: <UserManagement />,
    permission: "user-list-view",
  },
  {
    path: "my-profile/user-management/guest-users",
    element: <GuestUserManagement />,
    permission: "user-list-view",
  },
  {
    path: "/my-profile/user-management/ledproAdmin-users",
    element: <LedProAdminUserManagement />,
    permission: "user-list-view",
  },
  {
    path: "/my-profile/user-permission-management",
    element: <UserPermissionManagement />,
    permission: "user-list-view",
  },
  {
    path: "my-profile/billboard-settings",
    element: <Bilboard />,
    permission: "billboard-list-view",
  },
  {
    path: "my-profile/user-edit/:id",
    element: <UserEdit />,
    permission: "user-edit",
  },
  {
    path: "my-profile/user-edit",
    element: <UserEdit />,
    permission: "user-create",
  },
  {
    path: "my-profile/export-reports",
    element: <ExportReport />,
    permission: "report-list-view",
  },
  {
    path: "my-profile/export-reports/user-reports",
    element: <UserReportExport />,
    permission: "create-user-report",
  },
  {
    path: "my-profile/export-reports/billboard-reports",
    element: <MainLayout ChildElement={BillboardReportExport} />,
    permission: "create-billboard-report",
  },
  {
    path: "my-profile/export-reports/statistics-reports",
    element: <MainLayout ChildElement={StatisticsReportsExport} />,
    permission: "create-statistic-report",
  },
  {
    path: "/my-profile/services-and-items",
    element: <ServicesAndItems />,
    permission: "item-list-view",
  },
  {
    path: "/my-profile/general-settings",
    element: <GeneralSetting />,
    permission: "general-setting",
  },
  {
    path: "/my-profile/sms-credit",
    element: <SMS_CREDIT />,
    permission: "sms-credit",
  },

  //For income invoice
  {
    path: "/my-profile/income-invoice/awaiting-approval",
    element: (
      <AwaitingApproval
        title={INVOICE_STATUS.AWAITING_APPROVAL}
        type={INVOICE_TYPE.INCOME}
      />
    ),
    permission: "view-income-invoice-list",
  },
  {
    path: "/my-profile/income-invoice/paid",
    element: (
      <AwaitingApproval
        title={INVOICE_STATUS.PAID}
        type={INVOICE_TYPE.INCOME}
      />
    ),
    permission: "view-income-invoice-list",
  },
  {
    path: "/my-profile/income-invoice/cancelled",
    element: (
      <AwaitingApproval
        title={INVOICE_STATUS.CANCELLED}
        type={INVOICE_TYPE.INCOME}
      />
    ),
    permission: "view-income-invoice-list",
  },
  {
    path: "/my-profile/income-invoice/payment",
    element: (
      <AwaitingApproval
        title={INVOICE_STATUS.AWAITING_PAYMENT}
        type={INVOICE_TYPE.INCOME}
      />
    ),
    permission: "view-income-invoice-list",
  },

  // for expenses invoice
  {
    path: "/my-profile/expenses-invoice/awaiting-approval",
    element: (
      <AwaitingApproval
        title={INVOICE_STATUS.AWAITING_APPROVAL}
        type={INVOICE_TYPE.EXPENSE}
      />
    ),
    permission: "view-expenses-invoice-list",
  },
  {
    path: "/my-profile/expenses-invoice/paid",
    element: (
      <AwaitingApproval
        title={INVOICE_STATUS.PAID}
        type={INVOICE_TYPE.EXPENSE}
      />
    ),
    permission: "view-expenses-invoice-list",
  },
  {
    path: "/my-profile/expenses-invoice/cancelled",
    element: (
      <AwaitingApproval
        title={INVOICE_STATUS.CANCELLED}
        type={INVOICE_TYPE.EXPENSE}
      />
    ),
    permission: "view-expenses-invoice-list",
  },
  {
    path: "/my-profile/expenses-invoice/payment",
    element: (
      <AwaitingApproval
        title={INVOICE_STATUS.AWAITING_PAYMENT}
        type={INVOICE_TYPE.EXPENSE}
      />
    ),
    permission: "view-expenses-invoice-list",
  },

  // this is for create and edit invoice
  {
    path: "/my-profile/income-invoice/awaiting-approval/create-invoice",
    element: <CreateEditAddInvoice type={INVOICE_TYPE.INCOME} />,
    permission: "create-income-invoice",
  },
  {
    path: "/my-profile/expenses-invoice/awaiting-approval/create-invoice",
    element: <CreateEditAddInvoice type={INVOICE_TYPE.EXPENSE} />,
    permission: "create-expenses-invoice",
  },
  {
    path: "/my-profile/income-invoice/genrate-invoice",
    element: <InvoiceGenrate />,
    permission: "view-income-invoice-list",
  },
  {
    path: "/my-profile/expenses-invoice/genrate-invoice",
    element: <InvoiceGenrate />,
    permission: "view-expenses-invoice-list",
  },
];
